<template>
	<div>
		<div class="grid grid-cols-1 lg:grid-cols-3 border border-blue-200">
			<div class="col-span-1 p-4 border-r">
				<div class="opacity-75 text-13 font-medium mb-1">
					Total Earned
				</div>

				<sm-loader v-if="loading" />
				<div
					class="text-gray-500 text-lg font-semibold flex flex-row items-center"
					v-else
				>
					<ion-icon name="cash-outline" class="text-xl mr-2"></ion-icon>
					{{ totalEarned | formatAmount }}
				</div>
			</div>
			<div class="col-span-1 p-4 border-r">
				<div class="opacity-75 text-13 font-medium mb-1">
					Total Redeemed
				</div>

				<sm-loader v-if="loading" />
				<div
					class="text-gray-500 text-lg font-semibold flex flex-row items-center"
					v-else
				>
					<ion-icon name="cash-outline" class="text-xl mr-2"></ion-icon>
					{{ totalRedeemed | formatAmount }}
				</div>
			</div>
			<div class="col-span-1 p-4">
				<div class="opacity-75 text-13 font-medium mb-1">
					Balance
				</div>

				<sm-loader v-if="loading" />
				<div
					class="text-gray-500 text-lg font-semibold flex flex-row items-center"
					v-else
				>
					<ion-icon name="cash-outline" class="text-xl mr-2"></ion-icon>
					{{ balance | formatAmount }}
				</div>
			</div>
		</div>
		<div class="mt-10">
			<h3 class="text-lg font-bold mt-2 mb-2">Wallet Redemption</h3>
			<div class="border border-solid border-blue-200">
				<div class="flex flex-col">
					<div class="duration-300">
						<datatable
							:data="walletTransactions"
							:columns="columns"
							:fillable="false"
							ref="table"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		staff: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			rewardWallet: {},
			loading: false,
			columns: [
				{
					name: "amount",
					th: "Redemption Amount",
					render: (redemption) =>
						this.$options.filters.formatAmount(redemption?.amount),
				},
				{
					name: "type",
					th: "Type",
					render: (redemption) =>
						redemption?.type === "cr" ? "Credit" : "Debit",
				},
				{
					name: "reward_wallet",
					th: "Wallet Balance",
					render: (redemption) =>
						this.$options.filters.formatAmount(
							redemption?.currentBalance / 100
						),
				},
				{
					name: "transaction_date",
					th: "Transaction Date",
				},
			],
		};
	},
	computed: {
		walletTransactions() {
			return this.rewardWallet?.reward_wallet_transactions;
		},
		balance() {
			return this.rewardWallet?.balance;
		},
		totalEarned() {
			return this.walletTransactions
				?.filter((transaction) => transaction.type === "cr")
				?.reduce((total, transaction) => {
					return total + Number(transaction?.amount);
				}, 0);
		},
		totalRedeemed() {
			return this.walletTransactions
				?.filter((transaction) => transaction.type === "dr")
				?.reduce((total, transaction) => {
					return total + Number(transaction?.amount);
				}, 0);
		},
	},
	beforeMount() {
		this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			this.loading = true;
			await this.sendRequest("admin.wallets.users", this.staff?.id, {
				success: (response) => (this.rewardWallet = response.data.data[0]),
				error: (error) => console.log(error),
			});
			this.loading = false;
		},
	},
};
</script>